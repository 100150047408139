import PropTypes from 'prop-types'
import type { FC, ReactNode } from 'react'
import { useState } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useAuth } from '../../hooks/use-auth'
import AuthorizationRequired from '../../pages/401'

interface AuthGuardProps {
    children: ReactNode
    permissions?: string[]
    role?: string
}

export const AuthGuard: FC<AuthGuardProps> = (props) => {
    const { children, permissions, role } = props
    const auth = useAuth()
    const location = useLocation()
    const [requestedLocation, setRequestedLocation] = useState<string | null>(
        null
    )

    if (!auth.isAuthenticated) {
        if (location.pathname !== requestedLocation) {
            setRequestedLocation(location.pathname)
        }

        return <Navigate to="/authentication/login" />
    }

    // Here check the user permissions
    const canViewPermission =
        !permissions ||
        permissions.length === 0 ||
        permissions.every((r) => auth.permissions?.includes(r))

    if (!canViewPermission) {
        if (location.pathname !== requestedLocation) {
            setRequestedLocation(location.pathname)
        }

        return <AuthorizationRequired />
    }

    // Here check the user role
    const canViewRole = !role || role === auth.user.role

    if (!canViewPermission || !canViewRole) {
        if (location.pathname !== requestedLocation) {
            setRequestedLocation(location.pathname)
        }

        return <AuthorizationRequired />
    }

    // This is done so that in case the route changes by any chance through other
    // means between the moment of request and the render we navigate to the initially
    // requested route.
    if (requestedLocation && location.pathname !== requestedLocation) {
        setRequestedLocation(null)
        return <Navigate to={requestedLocation} />
    }

    return <>{children}</>
}

AuthGuard.propTypes = {
    children: PropTypes.node,
    permissions: PropTypes.any,
    role: PropTypes.string,
}
